
import Vue from "vue";
let proEnv = require('../../config/prod.env');  // 生产环境
let devEnv = require('../../config/dev.env');  // 测试环境

export default ({method, path, body, isMock=false, ...config}) => {
  
  let baseUrl = process.env.NODE_ENV === 'production' ? proEnv.BASE_URL : (isMock ? 'mock/' : devEnv.BASE_URL);
  let request = null;
  if(method === 'get') {
    request = Vue.http({
      method: 'GET',
      url: baseUrl + path,
      params: body,
      ...config,
    });
  }else {
    request = Vue.http[method](baseUrl + path, body, {
      config,
    });
  }
  
  return request.then( res => new Promise(resolve => resolve(res.body) ) );
}
