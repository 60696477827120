import Remote from './request';
const isMock = false;

export const DATA = {
  // 获取案例
  getDemo(body){
    return Remote({
      method: 'get',
      path: 'api/antifake/getDemo',
      loading: true,
      body,
    });
  },

  // 获取视频案例
  getVideoDemo(body){
    return Remote({
      method: 'get',
      path: 'api/antifake/getVideoDemo',
      loading: true,
      body,
    });
  },

  // 获取溯源软件案例
  getTraceSourceAppDemo(body) {
    return Remote({
      method: 'get',
      path: 'api/antifake/getTraceSourceAppDemo',
      loading: true,
      body,
    });
  },

  // 获取溯源算法案例
  getTraceSourceAlgorithmDemo(body) {
    return Remote({
      method: 'get',
      path: 'api/antifake/getTraceSourceAlgorithmDemo',
      loading: true,
      body,
    });
  },

  // 图像检测
  imageCheck({fileUrl}){
    return Remote({
      method: 'post',
      path: `api/antifake/imageCheck?fileUrl=${fileUrl}`,
      loading: false,
    });
  },

  // 视频检测
  videoCheck({fileUrl}){
    return Remote({
      method: 'post',
      path: `api/antifake/videoCheck?fileUrl=${fileUrl}`,
      loading: false,
    });
  },

  // 上传文件
  uploadFile(body, fileType){
    return Remote({
      method: 'post',
      path: `api/antifake/uploadFile?fileType=${fileType}`,
      loading: true,
      body,
    });
  },

  //溯源检测
  traceSourceCheck({ fileUrl ,type}) {
    return Remote({
      method: 'post',
      path: `api/antifake/traceSourceCheck?fileUrl=${fileUrl}&type=${type}`,
      loading: false,
      // showMessage: false,
    });
  },

  // 一体机
  deepfakeCheck({fileUrl, inferenceTimes, accelerate, machId}){
    return Remote({
      method: 'post',
      path: `api/antifake/deepfakeCheck?fileUrl=${fileUrl}&inferenceTimes=${inferenceTimes}&accelerate=${accelerate}&macId=${machId}`,
      loading: false,
      showMessage: false,
    });
  },

  // 身份证检测
  idCardCheck({fileUrl}){
    return Remote({
      method: 'post',
      path: `api/antifake/idCardCheck?fileUrl=${fileUrl}`,
      loading: false,
      showMessage: false,
    });
  },

  //行程码
  codeCheck({fileUrl}){
    return Remote({
      method: 'post',
      path: `api/antifake/journeyCodeCheck?fileUrl=${fileUrl}`,
      loading: false,
    });
  },

  // 一般ps图片检测
  normalImageCheck({fileUrl}){
    return Remote({
      method: 'post',
      path: `api/antifake/normalImageCheck?fileUrl=${fileUrl}`,
      loading: false,
    });
  },

  // 生成视频
  vivoDetection(body) {
    return Remote({
      method: 'post',
      path: 'api/tianjin/vivoDetection',
      loading: false,
      body,
    });
  },

  // 一体机
  videoDeepfakeCheck(body) {
    return Remote({
      method: 'post',
      path: 'api/tianjin/deepfakeCheck',
      loading: false,
      body,
    });
  },

  // 文本检测
  textCheck(body){
    return Remote({
      method: 'post',
      path: `api/antifake/textCheck`,
      loading: true,
      body,
    });
  },

  // 银行卡检测
  bankCardCheck({fileUrl}){
    return Remote({
      method: 'post',
      path: `api/antifake/bankCardCheck?fileUrl=${fileUrl}`,
      loading: false,
      showMessage: false,
    });
  },
};


export const DEFENSE = {
  // 获取案例
  getDemo(body){
    return Remote({
      method: 'get',
      path: 'api/defense/getDemo',
      loading: true,
      body,
    });
  },

  uploadFile(body, fileType){
    return Remote({
      method: 'post',
      path: `api/defense/uploadFile?fileType=${fileType}`,
      loading: true,
      body,
    });
  },

  // 生成视频
  vivoDetection(body) {
    return Remote({
      method: 'post',
      path: 'api/defense/vivoDetection',
      loading: false,
      body,
    });
  },

  // 一体机
  deepfakeCheck(body) {
    return Remote({
      method: 'post',
      path: 'api/defense/deepfakeCheck',
      loading: false,
      body,
    });
  },

  //视频伪造
  videoCheck(body) {
    return Remote({
      method: 'post',
      path: `api/defense/videoDeepFake`,
      loading: false,
      body
    });
  },
}